<template>
    <section class="pagination__wrapper pagination__wrapper__type--3" v-if="pageFiter.view">
		<div class="pagination">
			<div class="pagination__box">
				<button  class="pagination__btn__allLeft"
                 :data-test="pagination.total"
                 :disabled="pagination.current == 1 || disabled"
                 :class="{'disabled' : pagination.current == 1 || pageFiter.array.length < 2 }"
                 @click="movePage($event, pageFiter.array[0][0])">
					처음다음페이지
				</button>
				<button class="pagination__btn__left"
                :disabled="pagination.current == 1 || disabled"
                :class="{'disabled' : pagination.current == 1}"
                @click="movePage($event, pagination.current-1)">
					이전페이지
				</button>
				<ul class="pagination__number">
					<li class="pagination__list" v-for="(number, index) in this.pageFiter.view" :key="index">
						<span class="fb__font__n" v-if="pagination.current == number"><b>{{number}}</b></span>
						<button class="fb__font__n" v-else  :disabled="disabled"  @click="movePage($event, number)">{{number}}</button>
					</li>
				</ul>
				<button  class="pagination__btn__right"
                 :disabled="pagination.current == pageFiter.array[pageFiter.array.length - 1][pageFiter.array[pageFiter.array.length - 1].length - 1]  || disabled"
                 :class="{'disabled' : pagination.current == pageFiter.array[pageFiter.array.length - 1][pageFiter.array[pageFiter.array.length - 1].length - 1]}"
                 @click="movePage($event, pagination.current+1)">
					다음페이지
				</button>
				<button  class="pagination__btn__allRight"
                 :disabled="pagination.current >= pageFiter.array[pageFiter.array.length - 1][0]  || disabled"
                 :class="{'disabled' : pagination.current >= pageFiter.array[pageFiter.array.length - 1][0]}"
                 @click="movePage($event, pageFiter.array[pageFiter.array.length - 1][pageFiter.array[pageFiter.array.length - 1].length-1])">
					끝다음페이지 
				</button>
			</div>
		</div>
	</section>
</template>
<script>
    export default {
        name : "pagination-type-3-component",

		data() {
			return {
				pageFiter : {
					string: "",
					array: [],
					view: null,
				}
			}
		},

		props: {
            pagination: {
                type: Object,
				default: () => {
					return {
						id: null,
						current: 1,
						rowCount: 10,
						total: 10,
					}
				},
				required: true
			},
			
			disabled: {
				type: Boolean,
				default: false
			}
		},

		created() {
			for(let i= 0; i <= Math.ceil(this.pagination.total / this.pagination.rowCount) ; i++) {
				if(i != 0) {
					let gap = i % 10 == 0 ? '/' : ','
					this.pageFiter.string += i + gap;
				}
			};

			if("1," != this.pageFiter.string) {
				this.pageFiter.array = this.pageFiter.string.split('/').map(e => {
					return e.split(',').filter(n => n).map( n => Number(n));
				});

				 //array 마지막이 아무것도 담겨있지 않으면 삭제
				if (this.pageFiter.array && this.pageFiter.array.length && this.pageFiter.array[this.pageFiter.array.length - 1].length == 0 ) this.pageFiter.array.pop();

				this.pageFiter.view = this.pageFiter.array[this.pagination.current % 10 == 0 ? this.pagination.current / 10 - 1 : Math.floor(this.pagination.current / 10)];
			}
		},

		watch: {
			pagination: {
				handler() {
					this.pageFiter.array = [];
					this.pageFiter.view = null;
					this.pageFiter.string = "";
					
					for(let i= 0; i <= Math.ceil(this.pagination.total / this.pagination.rowCount) ; i++) {
						if(i != 0) {
							let gap = i % 10 == 0 ? '/' : ','
							this.pageFiter.string += i + gap;
						}
					};

					if("1," != this.pageFiter.string) {
            this.pageFiter.array = this.pageFiter.string.split('/').filter(segment => segment.trim() !== '').map(e => { //공백 문자열 제거
              return e.split(',').filter(n => n).map( n => Number(n));
            });
						
						this.pageFiter.view = this.pageFiter.array[this.pagination.current % 10 == 0 ? this.pagination.current / 10 - 1 : Math.floor(this.pagination.current / 10)];
					}
				},
				deep: true
			}
		},
		
        methods: {
            movePage(e, page) {
				this.$emit('move-page', {
					e,
					id: this.pagination.id,
					page,
				});
			}
        }
    }
</script>
<style lang="scss" scoped>
	.pagination__wrapper {

		.pagination {
			margin-top: 40px;

			&__box {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&__number {
				display: inline-block;
			}

			&__list {
				display: inline-block;
				width: 22px;
				height: 38px;
				margin: 0 6px;
				line-height: 38px;

				span {
					color: #b2b2b2;
					font: {
						size: 16px;
					}

					b {
						color: #000;
					}
				}

				
				& > button {
					top: 0 !important;
					color: #b2b2b2;
					font: {
						size: 16px;
					}
					text-indent: 0 !important;
				}
			}

			button {
				position: relative;
				// top: 4px;
				text-indent: -9999px;

				&.disabled {
					opacity: 0;
					visibility: hidden;
				}
			}

			&__btn {

				&__left {
					width: 7px;
					height: 12px;
					margin-right: 7px;
					background: url(/pc/assets/images/common/icon-pagination-arrow.png) no-repeat 0 0;
				}

				&__allLeft {
					width: 14px;
					height: 13px;
					margin-right: 7px;
					background: url(/pc/assets/images/common/icon-pagination-arrow2.png) no-repeat 0 0;
				}

				&__right {
					width: 7px;
					height: 12px;
					margin-left: 7px;
					background: url(/pc/assets/images/common/icon-pagination-arrow.png) no-repeat right 0;
				}

				&__allRight {
					width: 14px;
					height: 13px;
					margin-left: 7px;
					background: url(/pc/assets/images/common/icon-pagination-arrow2.png) no-repeat right 0;
				}
			}

			&__inner {
				display: inline-block;
				position: relative;
				width: 64px;

				span {
					display: block;
					float: left;
					width: 50%;
					text-align: center;
				}

				&:after {
					display: block;
					clear: both;
					content: "";
				}

				&:before {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: 1px;
					height: 11px;
					margin-top: -4px;
					background: #797979;
					content: "";
				}
			}

			&__now {
				color: #000;
			}

			&__max {
				color: #777777;
			}

			&__list {

				span {
					.test {
						color: #ff3e2f;
					}
				}
			}
		}
	}
</style>